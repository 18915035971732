<template>

  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">
        <font-awesome-icon 
          icon="circle-user" 
          class="color-secondary me-1"
        />
        <span  v-if="!editing">
          Crear perfil
        </span>
        <span  v-if="editing">
          Editar perfil
        </span>
      </h5>
      <button 
        type="button" 
        class="modal-custom-close"
        data-bs-dismiss="modal" 
        @click="back()"
        aria-label="Close">
        <font-awesome-icon icon="xmark"/>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-12 col-lg-6">

          <div class="row mt-3">
            <div class="col-12">
              <label class="text-secondary">
                Nombre categoria
              </label>
                <input
                  class="form-control input-custom"
                  type="text"
                  v-model="name"
                  maxlength="75"
                />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label class="text-secondary">
                Color etiqueta
              </label>

              <div class="wall-modal-perfil__input-container">
                <div class="wall-modal-perfil__colors">
                  <div
                    v-for="(co, i) in colors"
                    :key="i"
                    class="wall-modal-perfil__color"
                    :style="`background: ${co};`"
                    :class="color == co && 'picked'"
                    @click="color = co"
                  ></div>
                </div>
              </div>

            </div>
          </div>
    
        </div>
        <div class="col-12 col-lg-6">
          <label class="text-secondary">
              Adjunta una imagen
          </label>

          <UploadImg 
            @sendImg="handleMainImg"
            v-if="news_main_img.base64 == ''"
          />
          <div 
            v-if="news_main_img.base64 !== ''"
            class="upload-image"
          >
            <div class="upload-image-preview">
              <button 
                @click="deletePreview('main')"
                class="upload-image-preview-remove"   
              >
                  <font-awesome-icon icon="times"/>
              </button>
              <img 
                class="upload-image-preview-img"
                :src="news_main_img.base64"
              />
            </div>
          </div>
          
        </div>
      </div>
      
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        ref="closeModal"
        class="btn btn-custom-color-white rounded-pill mw-100" 
        data-bs-dismiss="modal"
        @click="back()"
      >
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>

      <button
        v-if="!editing"
        class="btn btn-custom-color-blue rounded-pill mw-100"
        :disabled="!validateButton()"
        @click="addNewProfile()"
      >
        Crear
      </button>
      <button
        v-if="editing"
        class="btn btn-custom-color-blue rounded-pill mw-100"
        :disabled="!validateButton()"
        @click="editingProfile()"
      >
        Guardar
      </button>
    </div>

    <Spinner v-if="show_spinner" />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="back()"
    />

  </div>

</template>

<script>
import UploadImg from "../UploadImg.vue";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";
import { mapActions } from "vuex";
export default {
  components: { UploadImg, Spinner, Status },
  props: {
    obj: Object,
  },
  data() {
    return {
      news_main_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      editing: false,
      name: "",
      color: "#0072f7",
      colors: [
        "#0072f7",
        "#357BF3",
        "#37C1EB",
        "#05CFC1",
        "#23BE61",
        "#F6C700",
        "#F26F28",
        "#E64141",
        "#EC206B",
      ],
      file_img: null,
      webpImg: null,
      show_spinner: false,
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      modal_status_msg: "",
      open_modal_status: false,
      modal_status: true,
      img_boundary:'profile'
    };
  },
  watch: {
    file_img(fil) {
      if (fil !== null) {
        if (fil == undefined) {
          setTimeout(() => {
            this.file(fil);
          }, 200);
        }
        if (fil !== undefined) {
          this.file(fil);
        }
      }
    },
  },
  computed: {},
  mounted() {
    if (this.obj != null) {
      this.editing = true;
      this.prepareEdition();
    }
  },
  methods: {
    ...mapActions("wallModule", [
      "addProfile","editProfile"
    ]),
    getProfileData(type){
      let imagen_ = '';
      let formato_ = '';
      let data_profile = {};
      if(type =="editar"){
        if (this.news_main_img.base64 !== this.obj.imagen_perfil) {
          imagen_ = this.webpImg.split(",")[1]
          formato_ = this.webpImg.split(":")[1].split(";")[0]
        }else{
          imagen_ = ""
          formato_ = ""
        }
      }else{
        imagen_ = this.webpImg.split(",")[1]
        formato_ = this.webpImg.split(":")[1].split(";")[0]
      }
      data_profile = {
        nombre_perfil: this.name,
        color: this.color,
        imagen: imagen_,
        formato_imagen: formato_,
        id_empresa:  this.id_empresa
      }
      if(type == 'editar') data_profile.id_perfil = this.obj.id_perfil;
      console.log('data to edit', data_profile);
      return data_profile;
    },
    async addNewProfile(){
      await this.addProfile(this.getProfileData()).then(res =>{
        if(res != null){
          this.$toast.open({
            message:`El perfil ha sido creada correctamente.`,
            type:'success',
            duration:6000,
            position:'top-right'
          })
          this.back();
          this.$refs.closeModal.click();
        }else{
          this.$toast.open({
            message:`Ocurrió un error al crear el perfil, intentalo nuevamente.`,
            type:'error',
            duration:0,
            position:'top-right'
          })
        }
      })
    },
    async editingProfile(){
      await this.editProfile(this.getProfileData('editar')).then(res =>{
        if(res != null){
          this.$toast.open({
            message:`El perfil ha sido editado correctamente.`,
            type:'success',
            duration:6000,
            position:'top-right'
          })
          this.back();
          this.$refs.closeModal.click();
        }else{
          this.$toast.open({
            message:`Ocurrió un error al editar el perfil, intentalo nuevamente.`,
            type:'error',
            duration:0,
            position:'top-right'
          })
        }
      })
    },
    validateButton() {
      var validate = false;
      if (
        this.color != "" &&
        this.name != "" &&
        this.news_main_img.base64 != ""
      ) {
        validate = true;
      }
      return validate;
    },
    back() {
      //this.obj = null;
      this.color = "";
      this.name = "";
      this.news_main_img.base64 = "";
      this.$emit("back");
    },
    //select imagen
    handleMainImg(img) {
      this.news_main_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
        all: img.all,
      };
      this.file_img = img.all;

      // console.log(this.post_img);
    },
    deletePreview(img_type) {
      if (img_type == "main") {
        this.news_main_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
          all: "",
        };
        this.file_img = null;
        this.webpImg = null;
      }
    },
    
    file(file) {
      fetch(file).then(res => res.blob()).then(blob_file => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        //declarar el cambas para la transformacion
        let currentImg = "";
        let webpImg = "";
        let convertedImg = "";
        //Deja en 0 las variables si están siendo utilizadas
        if (currentImg != "" || webpImg != "" || convertedImg != "") {
          URL.revokeObjectURL(currentImg);
          convertedImg = "";
          currentImg = "";
          webpImg = "";
        }
        //lee el archivo
        currentImg = URL.createObjectURL(blob_file);
        webpImg = new Image();
        //utiliza onload  para extraer y hacer el cambio de tipo de archivo
        webpImg.onload = () => {
          canvas.width = webpImg.naturalWidth;
          canvas.height = webpImg.naturalHeight;
          //pinta la imagen en el canvas
          ctx.drawImage(webpImg, 0, 0, canvas.width, canvas.height);
          //combierte la imagen del canvas a webp
          convertedImg = canvas.toDataURL("image/webp", 1.0);
          //insert
          this.webpImg = convertedImg
          //console.log("webp", convertedImg);
        };
        webpImg.src = currentImg;
      })
    },
    prepareEdition() {
      this.color = this.obj.color;
      this.name = this.obj.nombre_perfil;
      this.news_main_img.base64 = this.obj.imagen_perfil;
    },
  },
};
</script>

<style></style>
